:root {
  --primary-color: #F7A8B8;
  --secondary-color: #55CDFC;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: large;
}

mark.lightblue {
  color: var(--secondary-color);
  background: none;
}

mark.lightpink {
  color: var(--primary-color);
  background: none;
}

mark.big {
  font-size: 2em;
  background: none;
}

.Navbar {
  background-color: var(--primary-color);
  display: flex;
}

.Introduction {
  margin: auto;
  width: 60%;
  text-align: left;
  font-size: 1.6em;
  padding-top: 300px;
}

.WorkHistory {
  width: 40%;
  text-align: left;
  float: right;
  padding: 1em;
  padding-right: 10%;
}

.Projects {
  width: 40%;
  float: left;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 10%;
}

.Socials {
  color: var(--primary-color);
  font-size: xx-large;
  width: 10%;
  position: fixed;
  bottom: 0px;
  right: 0px;
  text-align: center;
}

a:visited {
  color: var(--secondary-color);
}

a {
  color: var(--primary-color);
}

li:not(:last-child) {
  margin-bottom: 1em;
}

.Socials li {
  list-style-type: none;
  padding: .25em;
  margin-bottom: 0;
}

.App {
  text-align: center;
}